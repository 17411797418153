import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import imageEdu from 'static/images/PREdu.png';
import tabletAbout from 'static/images/tablet-about.png';
import imageCert from 'static/images/certificate.png';



export default function About() {

    return (
        <React.Fragment>
            <div id="about" className="container section">
                <Grid container spacing={2}>
                    <Grid container item xs={12} md={4}>
                        <Grid item xs={12} style={{marginBottom:'10px'}}>
                        <Card className="card">
                            <div className="graduate-container">
                                <img src={imageEdu} className="graduate" width="100%" alt="Pradeep Rathi Graduate" />
                                <img src={tabletAbout} className="graduate-tablet" width="100%" alt="Pradeep Rathi Graduate" />
                            </div>
                            <div className="degree-container">
                                <img src={imageCert} className="degree" style={{verticalAlign:'bottom'}} width="100%" alt="Pradeep Rathi Graduate" />
                            </div>
                        </Card>
                        </Grid>

                    </Grid>

                    <Grid item xs={12} md={8}>
                            <Paper className="about-content">
                            <div className="belief desktop-version">
                <p className="belief-quote">" Today is the first day <br/> of the rest of your life."</p>
                <br/>
                To me it means one is not limited by one’s past. Any day or any moment in one’s life by choosing that moment and making a choice we can change our life’s journey, destiny and achieve the impossible. To me it reflects that you are a free soul where your past is bygone, today is new and changeable, and tomorrow is infinite only limited by your dreams and boundaries set by oneself. 
                <br />
                <br />
                My believe is to pursue my heart and dreams. Do what truly I believe in and try to achieve and attempt the impossible every day. My life is not a practice run, this is my only life and it is being spent as we speak. The moment you realize your infinite freedom you can truly believe this is the first day of the rest of my life.
                <br/>
                <p style={{ position: 'relative',textAlign:'right', margin:'15px 0'}}>-Pradeep Rathi</p>
                </div>
                            <p>
                            Mr. Rathi is an Entrepreneurship and Innovation ecosystem enabler with 34+ years successful experience focused on ROI, Broad Leadership, Shared Value, CSR and Operational Expertise based out in Mumbai, India. 
                            In his corporate career he has headed Limited Companies and managed global operations. He has his business interests in Real Estate, Financial Services and Business Startup Incubation &amp; Acceleration.    
                            </p> 
                            <p>
                            He is incredibly passionate about fostering entrepreneurship, grassroots leadership, strong communities and Innovations. With his deep love for creative minds, adventure, coffee and travel, 
                            Mr Rathi is currently The Founder and Mentor-in-Chief of a Virtual Startup Incubator - where he works with amazing leaders, Entrepreneurs, Mentors, Coaches, Innovators and Investors to strengthen and empower local startup ecosystem and make the client startup companies successful. 
                            He has been involved in various startup initiatives, community empowerment and leadership development programs, and bringing innovation and energy into the Startup ecosystem. 
                            </p>
                            <p>
                            Mr. Rathi is also an Organizer, Mentor, Advisor of startup events in universities and colleges all over India. He plays a key role in assisting colleges and academic institutions establish unique and innovative Startup Incubator and Startup Ecosystem for the students and the community they serve. 
                            He is a thinker, working with mission to enable, Inspire and Motivate people to Create Great Products, Build Great Companies and "Change the World".
                            </p>
                            <p>
                            Pradeep is also Member of the Academic Council of University of Jammu for the period 2018 to 2021. He is on Computer Society of India’s (CSI) National Executive Committee as Regional Vice-President Region 6 (Maharashtra &amp; Goa) for the period 2018-20. 
                            He National Convener for CSI’s SIG on Entrepreneurship &amp; Innovation for the period 2019-21. He is the Advisor, Mentor and Incubation Partner with e-Cell and Abhyuday of IIT Bombay for their flag ship events e-Summit, Eureka and Action Plan. 
                            He is also Advisor cum Mentor to FIRST the startup incubator of IIT Kanpur and with TISS Mumbai he is jointly mentoring social startups. He is also attached to several colleges as Advisor &amp; Mentor to their e-Cell and startup entrepreneurship programs. He is also Member on Advisory Board of Maritime Research Centre Pune for their education and upskilling programs. 
                            </p>
                            <p>
                            Finally, Pradeep's interests range from entrepreneurship to education, startups to sustainability, social impact to grassroots innovations and nurturing students into Entrepreneurs.
                            </p>
                        </Paper>
                        <Box style={{marginTop:'10px',textAlign:'right'}}>
                        <a href="https://www.linkedin.com/in/pjrathi/" rel="noopener noreferrer" target="_blank" className="linkedin"><i className="fa fa-linkedin"></i></a>
                        <a href="https://www.linkedin.com/in/pjrathi/" rel="noopener noreferrer" target="_blank"><p>Click for detailed profile</p></a>
                        </Box>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )

}
