import React from 'react';

class Footer extends React.Component {
    render(){
   return (
       <React.Fragment>
           <div className="footer">
                <small>www.pradeeprathi.com</small>
                <hr/>
                <small>Trademarked ™ &amp; Copyrighted © 2019-2020</small>
           </div>
       </React.Fragment>
   );
    }
 }
 export default Footer; 