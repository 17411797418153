import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import three from '../../../static/images/logos/3izone.png';
import ard from '../../../static/images/logos/ard.png';
import arogyagram from '../../../static/images/logos/arogyagram.png';
import pepperfry from '../../../static/images/logos/pepperfry.png';
import sampan from '../../../static/images/logos/sampan.png';



export default function Companies() {
    return (
        <div className="container">
            <Grid container style={{width:'100%'}} spacing={4} justify="space-evenly"
            >
                <Grid item xs={6} sm={4} md={2}>
                    <Paper className="company-logo-container">
                        <div className="top-container">
                            <a href="https://3izone.com/" target="_blank" rel="noopener noreferrer">
                        <img className="company-logo three" src={three} alt="3iZone" width="100%" />
                            </a>
                        </div>
                        <hr/>
                        <div className="bottom-container">
                            <h4 className="company-title">3i Zone</h4>
                            <p className="company-description">Startup Incubator cum Accelerator</p>
                            <p className="company-website"><b>Website:</b><br/> <a href="https://3izone.com/" target="_blank" rel="noopener noreferrer">www.3izone.com</a></p>                            
                            <p className="company-email"><b>Email:</b><br/> <a href="mailto:pr@3izone.com">pr@3izone.com</a></p>
                        </div>
                    </Paper>
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                    <Paper className="company-logo-container">
                        <div className="top-container">
                        <a href="https://www.arogyagram.com/" target="_blank" rel="noopener noreferrer">
                        <img className="company-logo" src={arogyagram} alt="Arogyagram" width="100%" />

                        </a>
                        </div>
                        <hr/>
                        <div className="bottom-container">
                            <h4 className="company-title">Arogyagram</h4>
                            <p className="company-description">An initiative of Saraswati Aastha Trust in CSR consulting</p>
                            <p className="company-website"><b>Website:</b><br/> <a href="https://www.arogyagram.com/" target="_blank" rel="noopener noreferrer">www.arogyagram.com</a></p>                            
                            <p className="company-email"><b>Email:</b><br/> <a href="mailto:pjr@arogyagram.com">pjr@arogyagram.com</a></p>
                        </div>
                    </Paper>
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                    <Paper className="company-logo-container">
                        <div className="top-container">
                            <a href="https://peppergroup.in/" target="_blank" rel="noopener noreferrer">
                            <img className="company-logo" src={pepperfry} alt="" width="100%" />
                            </a>
                        </div>
                        <hr/>
                        <div className="bottom-container">
                            <h4 className="company-title">Pepper Advisory</h4>
                            <p className="company-description">A real estate and hospitality consulting company</p>
                            <p className="company-website"><b>Website:</b><br/> <a href="https://peppergroup.in/" target="_blank" rel="noopener noreferrer">www.pepperadvisory.in</a></p>                            
                            <p className="company-email"><b>Email:</b><br/> <a href="mailto:pjr@peppergroup.in">pjr@peppergroup.in</a></p>
                        </div>
                    </Paper>
                </Grid>

                <Grid item xs={6} sm={4} md={2}>
                    <Paper className="company-logo-container">
                        <div className="top-container">
                        <a href="https://arjunrathi.com/" target="_blank" rel="noopener noreferrer">
                        <img className="company-logo arjun" src={ard} alt="" width="100%" />
                        </a>
                        </div>
                        <hr/>
                        <div className="bottom-container">
                            <h4 className="company-title">Arjun Rathi Design</h4>
                            <p className="company-description">A multi-displinary studio working across Architecture &amp; Industrial Design</p>
                            <p className="company-website"><b>Website:</b><br/> <a href="https://arjunrathi.com/" target="_blank" rel="noopener noreferrer">www.arjunrathi.com</a></p>                            
                            <p className="company-email"><b>Email:</b><br/> <a href="mailto:pjrathi61@gmail.com">pjrathi61@gmail.com</a></p>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <Paper className="company-logo-container">
                        <div className="top-container">
                        <a href="http://www.sampansoaps.com/" target="_blank" rel="noopener noreferrer">
                        <img className="company-logo sampan" src={sampan} alt="" width="100%" />                            
                        </a>
                        </div>
                        <hr/>
                        <div className="bottom-container">
                            <h4 className="company-title">Moarvi Exports Pvt. Ltd.</h4>
                            <p className="company-description">Manufacturers of Sampan Handcrafted Soaps</p>
                            <p className="company-website"><b>Website:</b><br/> <a href="http://www.sampansoaps.com/" target="_blank" rel="noopener noreferrer">www.sampansoaps.com</a></p>                            
                            <p className="company-email"><b>Email:</b><br/> <a href="pjr@sampansoaps.com">pjr@sampansoaps.com</a></p>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}
