import React from 'react';
import Grid from '@material-ui/core/Grid';
import potrait from 'static/images/potrait.jpg';
import mainImage from 'static/images/PradeepRathi.png';
import potraitMobile from 'static/images/potrait-mobile.jpg';


export default function Header() {

    return (
        <React.Fragment>
            <div className="banner container" style={{paddingLeft:'0'}}>
                <Grid container spacing={4} style={{height:'inherit'}}>
                    <Grid item xs={12} sm={2} id="images-grid" style={{height:'inherit'}}>
                        <div className="potrait">
                            <img src={potrait} alt="Three images" />
                            </div> 
                    </Grid>
                    <Grid item xs={12} sm={10} style={{height:'inherit'}}>
                        <div className="intro">
                        <div className="quote-container">
                            <h2 className="quote">Today is the first day</h2>
                            <h2 className="quote">of the rest of your life.</h2>
                            </div>
                            <div className="main-title-container">
                            <h2 className="main-title">
                            <b className="first-name">Pradeep</b>
                            <b className="last-name">Rathi</b>
                            </h2>
                            </div>

                            <img src={mainImage} className="main-image" alt="Pradeep Rathi" width="100%"/>
                        </div>
                    </Grid>
                    <Grid>
                    <div className="profile-container">
                <h3 className="profile">Serial Entrepreneur | Advisor | Mentor-in-Chief | Startup Evangelist</h3>                
                </div>
                    </Grid>
                </Grid>
                
                <div className="mobile-potrait">
                    <img src={potraitMobile} alt="Pradeep Rathi"/>
                </div>
            </div>
        </React.Fragment>
    )
}
