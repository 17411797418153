import React from 'react';
import './static/styles/main.scss';
import Home from 'pages/home/Home';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";
function App() {
  return (
    <div className="App">
      <React.Fragment>
      <Router>
        <Switch>
          <Route path="/" exact={true} component={Home} />
          <Route render={() => <h1>Page not found</h1>} />
        </Switch>
      </Router>
    </React.Fragment>
    </div>
  );
}

export default App; 
