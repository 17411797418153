import React, { Component } from 'react';
import Layout from 'components/Layout';
import Header from './components/Header';

import About from './components/About'
import Companies from './components/Companies'
import Footer from 'components/Layout/Footer';
export default class Home extends Component {
    render() {
        return (
            <Layout>
                <Header />
                <div className="icon-bar">
                <a href="https://www.facebook.com/pjrathi" rel="noopener noreferrer" target="_blank" className="facebook"><i className="fa fa-facebook"></i></a> 
                <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="twitter"><i className="fa fa-twitter"></i></a> 
                <a href="https://www.linkedin.com/in/pjrathi/" rel="noopener noreferrer" target="_blank" className="linkedin"><i className="fa fa-linkedin"></i></a>
                </div>
                <div className="belief mobile-version">
                <p className="belief-quote">" Today is the first day <br/> of the rest of your life."</p>
                <br/>
                To me it means one is not limited by one’s past. Any day or any moment in one’s life by choosing that moment and making a choice we can change our life’s journey, destiny and achieve the impossible. To me it reflects that you are a free soul where your past is bygone, today is new and changeable, and tomorrow is infinite only limited by your dreams and boundaries set by oneself. 
                <br />
                <br />
                My believe is to pursue my heart and dreams. Do what truly I believe in and try to achieve and attempt the impossible every day. My life is not a practice run, this is my only life and it is being spent as we speak. The moment you realize your infinite freedom you can truly believe this is the first day of the rest of my life.
                <br/>
                <p style={{ position: 'relative',textAlign:'right', margin:'15px 0'}}>-Pradeep Rathi</p>
                </div>
                <About />
                <Companies />
                <Footer/>
            </Layout>
        );
    }
}
